<template>
  <div class="mt-5 mt-xl-4 custom-height position-relative">
    <img src="../../assets/images/contact-map-locations.webp" class="img-map" alt="..." />
    <img
      src="../../assets/images/contact-white-rounded-bg.svg"
      class="img-rounded-white"
      alt="..."
    />
    <div class="card-position" v-if="!message.includes('wrong')">
      <div class="card" style="z-index: 1">
        <div class="card-body text-center">
          <div>
            <svg
              width="65"
              height="64"
              viewBox="0 0 65 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M45.5999 26.2202L45.5999 26.2203L45.6058 26.2142C46.5633 25.2237 46.5717 23.6583 45.5999 22.6865C44.6313 21.7179 43.0347 21.7179 42.0661 22.6865L28.713 36.0396L22.9332 30.2598C21.9646 29.2912 20.3681 29.2912 19.3995 30.2598C18.4309 31.2284 18.4309 32.825 19.3995 33.7936L26.9461 41.3402C27.4122 41.8063 28.0456 42.0733 28.713 42.0733C29.3805 42.0733 30.0139 41.8063 30.4799 41.3402L45.5999 26.2202ZM6.33301 32C6.33301 17.5828 18.0825 5.83334 32.4997 5.83334C46.9169 5.83334 58.6663 17.5828 58.6663 32C58.6663 46.4172 46.9169 58.1667 32.4997 58.1667C18.0825 58.1667 6.33301 46.4172 6.33301 32Z"
                fill="#509920"
                stroke="#509920"
              />
            </svg>
          </div>
          <div class="heading-08 text-success-07 mt-3">
            {{ message }}
          </div>

          <button
            class="button btn-medium-wider btn-primary-solid text-button-02 w-100 mt-3"
            @click="OpenLoginModal()"
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.47533 5.44167L3.41699 10.5L8.47533 15.5583"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5836 10.5H3.55859"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Autentificare
          </button>
        </div>
      </div>
      <div class="card" style="margin-top: -20px; z-index: 0">
        <div class="card-body text-center text-button-01 fw-semibold text-white bg-primary-05">
          Vă mulțumim că v-ați înregistrat pe site-ul nostru!
          <br />
          Confirmăm primirea informațiilor dumneavoastră și validarea adresei de e-mail asociate
          contului.
          <br />Acum sunteți parte din comunitatea noastră și veți avea acces la toate
          funcționalitățile oferite.
        </div>
      </div>
    </div>
    <div class="card-position" v-else>
      <div class="card" style="z-index: 1">
        <div class="card-body text-center">
          <div>
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M64.5 32.5C64.5 14.8269 50.1731 0.5 32.5 0.5C14.8269 0.5 0.5 14.8269 0.5 32.5C0.5 50.1731 14.8269 64.5 32.5 64.5C50.1731 64.5 64.5 50.1731 64.5 32.5Z"
                fill="#DD2531"
              />
              <path d="M44 21L22 43" stroke="white" stroke-width="4" stroke-linecap="round" />
              <path d="M44 43L22 21" stroke="white" stroke-width="4" stroke-linecap="round" />
            </svg>
          </div>
          <div class="heading-08 mt-3">
            Ne pare rău, a avut loc o eroare.
          </div>
          <router-link
            class="button btn-medium-wider btn-primary-solid text-button-02 w-100 mt-3"
            :to="{ name: 'HomePage' }"
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.47533 5.44167L3.41699 10.5L8.47533 15.5583"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5836 10.5H3.55859"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Acasă
          </router-link>
        </div>
      </div>
      <div class="card" style="margin-top: -20px; z-index: 0">
        <div class="card-body text-center text-button-01 fw-semibold text-white bg-primary-05">
          Procesul de validare a adresei de email asociate contului nu a funcționat.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyEmailView',
  data() {
    return {
      message: '',
    };
  },
  methods: {
    VerifyEmail() {
      if (this.$route.query.token && this.$route.query.email) {
        const verification = {
          Email: this.$route.query.email,
          Code: this.$route.query.token.toString().replace(/\s/g, '+'),
        };
        this.$axios
          .post('/Auth/confirmEmail', verification)
          .then(() => {
            this.message = 'Email-ul a fost verificat cu success';
          })
          .catch(() => {
            this.message = 'Something went wrong!';
          });
      }
      this.message = 'Something went wrong!';
    },
    OpenLoginModal() {
      // eslint-disable-next-line no-undef
      $('#logInModal').modal('show');
    },
  },
  created() {
    this.VerifyEmail();
  },
};
</script>
<style scoped>
@media (min-width: 576px) {
  .custom-height {
    height: 85vh;
  }
}
.card-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-body {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 0px 5px 60px 0px #0000001a;
  border-radius: 24px;
  border: 0px;
  max-width: 435px;
  width: 100%;
  overflow: hidden;
}

.img-map {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.img-rounded-white {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
